/**
 *
 * Modal functionality
 *
 */

const openBtn = document.getElementById('open-modal');
const closeBtn = document.querySelectorAll('.close-modal');
const modalContainer = document.getElementById('modal-container');
const gotoBtn = document.getElementById('goto-store');
//const appCookie = document.cookie.replace(/(?:(?:^|.*;\s*)NyAppEnGang\s*\=\s*([^;]*).*$)|^.*$/, "$1");

// check if cookies has been accepted
const cookieNotice = getCookie('cookie_notice_accepted');

// Check if alert has been shown
const appCookie = getCookie('NyAppEnGang');

if (cookieNotice === "true" && appCookie !== "true") {
  if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
    document.body.classList.add('iphone');
    setTimeout(() => {
      openModal(modalContainer);
    }, 6000);
  } else if (navigator.userAgent.match(/Android/i)) {
    document.body.classList.add('android');
    setTimeout(() => {
      openModal(modalContainer);
    }, 6000);
  }
  document.cookie = "NyAppEnGang=true; expires=Fri, 31 Dec 9999 23:59:59 GMT"
}

/*=== Open modal ===*/
function openModal(modalContent) {
  modalContainer.classList.add('show-modal');
}

/*=== Close modal ===*/

function closeModal() {
  modalContainer.classList.remove('show-modal');
}

closeBtn.forEach((btn) => {
  btn.addEventListener('click', closeModal);
});

modalContainer.addEventListener('click', (e) => {
  if (e.target === modalContainer) {
    closeModal();
  }
});

function getCookie(name) {
  let cookie = {};
  document.cookie.split(';').forEach(function (el) {
    let [key, ...value] = el.split('=');
    cookie[key.trim()] = value.join('=').trim();
  })
  return cookie[name];
}